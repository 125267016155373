<template>
  <div class="flex-row-fluid col-lg-9">
    <div class="card card-custom gutter-b">
      <div class="card-header py-5">
        <h3 class="card-title align-items-start flex-column">
          <span class="card-label font-weight-bolder text-dark">Minhas propostas</span>
          <span class="text-muted mt-3 font-weight-bold font-size-sm">Acompanhe abaixo suas propostas</span>
        </h3>
        <div class="card-toolbar">
          <a v-b-modal.modal-new-proposal class="btn btn-success font-weight-bolder font-size-sm">
            <span class="svg-icon svg-icon-md svg-icon-white">
              <inline-svg src="/media/svg/icons/Communication/Add-user.svg" />
            </span>
            Nova proposta
          </a>
        </div>
      </div>
      <div class="card-body">
        <b-table
          responsive
          class="table table-head-custom table-vertical-center"
          :items="pageOfItems"
          :fields="fields"
          :busy="isLoading"
          stacked="sm"
          hover
          show-empty
        >
          <!-- Empty table -->
          <template #empty>
            <EmptyTable message="Você ainda não fez nenhuma proposta.." />
          </template>

          <!-- Busy table -->
          <template #table-busy>
            <BusyTable message="Aguarde enquanto carregamos suas propostas." />
          </template>

          <template #cell(created_at)="row">
            <span>
              <div class="font-weight-bolder text-primary mb-0 font-size-lg">
                {{ $moment(row.item.created_at).format('DD/MM/YYYY') }}
              </div>
              <div class="text-muted">
                {{ $moment(row.item.created_at).format('hh:mm:ss') }}
              </div>
            </span>
          </template>

          <template #cell(steps)="row">{{ row.item.steps.current_step.step.name }}</template>

          <template #cell(status)="row">
            <span class="badge" :class="'badge-' + row.item.status.class">{{ row.item.status.name }}</span>
          </template>

          <template #cell(actions)="row">
            <router-link
              v-if="row.item.status.id != 5"
              v-slot="{ href, navigate }"
              custom
              :to="{ name: 'proposal-show', params: { id: row.item.id } }"
            >
              <a class="btn btn-info btn-sm font-weight-bolder" :href="href" @click="navigate">
                <span class="svg-icon svg-icon-md svg-icon-white">
                  <inline-svg src="/media/svg/icons/General/Search.svg" />
                </span>
                Acompanhar
              </a>
            </router-link>
            <span v-else>--</span>
          </template>
        </b-table>
        <pagination
          v-if="!isLoading && proposal_list.length > 0"
          :items="proposal_list"
          @changePage="onChangePage"
        ></pagination>
      </div>
    </div>
    <!-- Modal new Proposal -->
    <b-modal id="modal-new-proposal" ref="modal-new-proposal" title="Nova Proposta" size="lg" modal hide-footer>
      <NewProposal />
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Pagination from '@/shared/content/widgets/table/Pagination.vue';
import EmptyTable from '@/shared/components/extras/EmptyTable';
import BusyTable from '@/shared/components/extras/BusyTable';
import NewProposal from './NewProposal';

export default {
  name: 'ListProposals',
  components: {
    Pagination,
    NewProposal,
    EmptyTable,
    BusyTable
  },
  data() {
    return {
      isLoading: false,
      fields: [
        { label: 'Data', key: 'created_at', sortable: true },
        { label: 'Proposta', key: 'id', sortable: true },
        { label: 'Etapa', key: 'steps', sortable: true },
        { label: 'Status', key: 'status', sortable: true },
        { label: 'Ações', key: 'actions', sortable: false }
      ],
      pageOfItems: []
    };
  },
  computed: {
    ...mapGetters(['proposal_list'])
  },
  watch: {
    async proposal_list() {
      this.pageOfItems = await this.proposal_list;
    }
  },
  async mounted() {
    this.$store.dispatch('SET_BREADCRUMB', [{ title: 'Emissão de SnailCoin' }, { title: 'Minhas Propostas' }]);

    this.isLoading = true;
    await this.$store.dispatch('GET_PROPOSAL_LIST');
    if (!this.isPagination) {
      this.pageOfItems = this.proposal_list;
    }

    this.isLoading = false;
  },
  methods: {
    onChangePage(pageOfItems) {
      // update page of items
      this.pageOfItems = pageOfItems;
    }
  }
};
</script>

<style></style>
